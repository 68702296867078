import React from 'react';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Main from 'layouts/Main';
import Container from 'components/Container';

const Partners = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
   
      <Box
        bgcolor={theme.palette.alternate.main}
        position={'relative'}
        minHeight={'calc(100vh - 247px)'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        height={1}
        marginTop={-12}
        paddingTop={12}
      >
        <Container>
          <Grid container>
            <Grid
              item
              container
              alignItems={'center'}
              justifyContent={'center'}
              xs={12}
              md={6}
            >
              <Box>
                <Typography
                  variant="h3"
                  component={'h3'}
                  align={isMd ? 'center' : 'center'}
                  sx={{ fontWeight: 700 }}
                >
                  WHY US ?
                </Typography>
                <br />
                <Typography
                  variant="h6"
                  component="p"
                  color="text.secondary"
                  align={isMd ? 'justify' : 'center'}
                >
                  We offer a creative and professionally satisfying environment that is innovative respectful and appreciative.
                  <br />
                  When we look for a new educator to join uson our journey, besides the obious requirments of Academic excellence and the ability to effectively inspire learning, we look for an attitude that connects with ours, We look for a real love for children, team skills and a passion for knowledge.<br/>
                  <br/>
                  “Mail your resume at <a href = " mailto:info@npsypr.edu.in" > info@npsypr.edu.in </a> or post it to our address. Resume should be accompanied
with handwritten write up on” What strategies will you employ to make your subject interesting to
students " or "How will you contribute to the child's holistic development" and recent passport
size coloured photograph.”

                  {/* Drop in your Resume <Link href={'/query-us'} underline="none" > Teaching</Link> 
                  |  <Link href={'/query-us'} underline="none" >Non-Teaching</Link> <br/> */}
                  
                     
                 
                </Typography>
                <Box
                  marginTop={4}
                  display={'flex'}
                  justifyContent={{ xs: 'center', md: 'flex-start' }}
                >
                  
                </Box>
              </Box>
            </Grid>
            <Grid item container justifyContent={'center'} xs={12} md={6}>
              <Box height={1} width={1} maxWidth={500}>
                <Box
                  component={'img'}
                  src={
                    'https://i.imgur.com/Ga2tO8B.jpg'
                  }
                  width={1}
                  height={1}
                  sx={{
                    filter:
                      theme.palette.mode === 'dark'
                        ? 'brightness(0.8)'
                        : 'none',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
  );
};

export default Partners;